<template>
  <div class="card-container unselect" @click="clickDetail(card)">
    <div class="card-img" :style="imgField(card.img)">
      <div class="card-img-hover" v-if="card.preview_url!==''">
        <div @click.stop>
          <a :href="card.preview_url" target="_blank">
            <button class="button is-gray" style="width:100%">데스크톱<br>미리보기</button>
          </a>
        </div>
        <button class="button is-gray" @click.stop.prevent="showMobilePreview(card.preview_url)">모바일<br>미리보기</button>
      </div>
    </div>
    <div class="card-content">
      <div class="flex-align">
        <div class="tag body7 sub3" v-for="(tag,idx) in card.tags.slice(0,tagLen)" :key="'tag-'+idx">#{{ tag }}</div>
      </div>
      <!-- 파트너 -->
      <!--<div class="flex-align margin-top-12" v-if="card.partner">
        <img style="width:32px;height:32px"
             class="margin-right-8"
             :src="card.partner.img" v-if="card.partner.img">
        <div class="body4 sub3">{{ card.partner.name }}</div>
      </div>-->
      <div class="subtitle6 main margin-top-12">{{ card.name }}</div>
      <div class="body4 sub2 ellipsis-2">{{ card.simple_desc }}</div>
      <!-- 가격 정보 -->
      <div v-if="discountedPrice(card)>0" class="margin-top-8">
        <div class="body6 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
        <div class="flex-align">
          <div class="margin-right-4 primary body4-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
          <div class="body4-bold">
            <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
            <span class="body4 sub2">원</span>
            <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
            <span class="body4 sub2 margin-left-4" v-if="card.category2!==42">~</span>
          </div>
        </div>

        <div class="flex-end" v-if="status!==undefined">
          <div :class="`card-status-${status}`">
            <svg v-if="status===2" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M20 6L9 17L4 12" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>{{ statusText(status) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardProductThemePc",
    props: {
      card: {
        type: Object
      }
    },
    created() {
      if(this.card.preview_url && this.card.preview_url.indexOf('demo')===-1) {
        let key = this.card.preview_url.split('.')[0].replace('https://','');
        this.card.preview_url += `/home?demo_test=${key}`;
      }

      this.setTagLength();
    },
    data() {
      return {
        tagLen: 0
      }
    },
    watch: {
      'card.tags'() {
        this.setTagLength();
      }
    },
    computed: {
      status() {
        /*const key = this.card.params.filter(i => { return i.name === 'key'})[0].value;
        return this.userService && this.userService.packages && this.userService.packages.indexOf(key) > -1 ? 1 : 0;*/
        return this.card.status;
      },
      grade() {
        let grade = this.card.params.filter(i=> { return i.name === '플러그인 등급' });
        if(grade.length === 0 || grade[0].value.length === 0) {
          return '기본';
        }
        else {
          return grade[0].value[0].name;
        }
      },
    },
    methods: {
      showMobilePreview(preview_url) {
        window.name = 'preview_parent';
        let popup = window.open('', 'previewPopup', 'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
        popup.location.href = preview_url;
      },
      setTagLength() {
        this.tagLen = 0;
        if(this.card.tags.length>0) {
          let len = 0;
          let limit = 20;
          this.card.tags.forEach(tag => {
            len += tag.length;
            if(len < limit) {
              this.tagLen++;
              limit = this.tagLen === 1 ? 19 : 16;
            }
          });
        }
        if(this.tagLen===0) {
          this.tagLen = 1;
        }
      },
      statusText(status) {
        const arr = {
          0: '추가',
          1: '신청 중',
          2: '이용 중'
        };

        return this.grade === '기본' ? arr[status] : status === 0 ? '신청' : arr[status];
      },
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 할인 여부
      isDiscount(product) {
        if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
        } else if (product.price.discount_start !== null && product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= product.price.discount_start;
        } else if (product.price.discount_start === null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= product.price.discount_end;
        } else {
          return false;
        }
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      imgField(img) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
        return style;
      },
      clickDetail(card) {
        this.routeGa('',`테마 카드(${card.name})`, card.name);

        let path = 'market';
        if(this.$route.path.indexOf('app_market')>-1) {
          path = 'app_market';
        }

        this.$router.push(`/${path}/detail?id=${card.id}`);
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .card-container
    background-color white
    border-radius 8px
    border 1px solid $gray2

  .card-img
    position relative
    width 100%
    height 195px
    border-top-left-radius 8px
    border-top-right-radius 8px
    background-color $gray4
    border 1px solid $gray3
  .card-img:hover
    .card-img-hover
      display grid

  .card-img-hover
    display none
    position absolute
    top 0
    left 0
    background rgba(0,0,0,0.3)
    width 100%
    height 100%
    align-items center
    justify-content center
    grid-template-columns repeat(2, 1fr)
    grid-column-gap 12px
    padding 36px

  .card-content
    padding 16px

  .tag
    border 1px solid $gray1
    border-radius 50px
    padding 2px 8px
    margin-right 4px

  .card-status-0
  .card-status-1
  .card-status-2
    border-radius 50px
    padding 0 20px
    line-height 32px
    height 32px
    margin-top 12px

  .card-status-0
    background-color $primary
    color white
  .card-status-1
    background-color $gray2
    color $sub2
  .card-status-2
    background-color $main
    color white
    svg
      margin-right 4px
      width 12px
      height 12px
</style>
